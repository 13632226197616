
import { mixins, Component, Prop } from 'nuxt-property-decorator';
import WidgetMixin from '~/mixins/widget.mixin';
import Swiper from 'swiper';
import { Navigation } from 'swiper/modules';
import { formatDate, isDateInRange } from '~/utils/date-helper';

@Component({
  name: 'dn-contest',
})
export default class Contest extends mixins(WidgetMixin) {
  @Prop({ type: Object, required: true }) data!: any;
  @Prop({ type: String, default: '' }) redirectUrl!: string;
  @Prop({ type: String, default: '' }) redirectText!: string;
  @Prop({ type: String, default: '' }) dateFrom!: string;
  @Prop({ type: String, default: '' }) dateTo!: string;

  formatDate = formatDate;
  isDateInRange = isDateInRange;

  private swiper: Swiper | null = null;
  private get swiperName(): string {
    return `swiper-contest`;
  }

  mounted() {
    this.$nextTick(() => {
      if (this.data.contests?.length > 1) {
        this.initSwiper();
      }
    });
  }

  beforeDestroy() {
    window.removeEventListener('load', this.initSwiper);
    if (this.swiper) {
      this.swiper.destroy?.();
      this.swiper = null;
    }
  }

  initSwiper() {
    if (document.readyState === 'complete') {
      this.createSwiper();
    } else {
      window.addEventListener('load', this.createSwiper);
    }
  }

  createSwiper() {
    this.swiper = new Swiper(`#${this.swiperName}`, {
      modules: [Navigation],
      navigation: {
        nextEl: `.swiper-button-next.${this.swiperName}`,
        prevEl: `.swiper-button-prev.${this.swiperName}`,
      },
      breakpoints: {
        0: {
          slidesPerView: 'auto',
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 'auto',
          spaceBetween: 16,
          loop: true,
        },
        1280: {
          slidesPerView: 'auto',
          spaceBetween: 32,
          loop: true,
        },
      },
    });
  }

  isContestDisabled(dateFrom: string, dateTo: string): boolean {
    const isStartInFuture = new Date(dateFrom) > new Date();
    return !isDateInRange(dateFrom, dateTo) && !isStartInFuture;
  }
}
